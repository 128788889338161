import React, { createRef, useEffect, memo } from 'react';
import loadable from '@loadable/component';
import { getParameterByName, loaded } from './../utils/helpers';
import { useSearch, useStore } from './../hooks/index';
import { isLogged, setToken } from '../services/customer';
import { getSwymRegId } from '../utils/wishlist';
import { dmptCheck, dmptClear } from '../utils/dmpt';
import HeaderAlertBar from '../components/header_alert_b';
import Header from './header';
import Footer from './footer';
import Customer from '../components/customer';
import Cart from '../components/cart';
import { QueryParamProvider } from 'use-query-params';
import { navigate } from 'gatsby';

const SearchResult = loadable(() => import('../components/Search/SearchResult'));

function Layout({
  children,
  hideChildren = true,
  after_footer_after_row,
  after_footer_before_row,
  className,
  location,
}) {
  const isMobile = loaded && window.innerWidth < 992;
  const { searching, query, indexName, setState } = useSearch();
  const { swymLoading, setState: setStateStore } = useStore();
  const existParams = loaded && window.location.search.length > 0;
  const searchRef = createRef();
  const hash = loaded ? location?.hash : '';

  useEffect(() => {
    const s = getParameterByName('q');
    if (s && s !== '') {
      setState((state) => ({ ...state, query: true, searching: true }));
    } else setState((s) => ({ ...s, searching: isMobile }));
  }, []);

  useEffect(() => {
    dmptCheck();

    if (!swymLoading && hash == '#logout' && isLogged()) {
      dmptClear();
      setStateStore((_state) => ({ ..._state, swymLoading: true }));
      setToken('');
      getSwymRegId(true, true).then(() => {
        setStateStore((_state) => ({ ..._state, swymLoading: false }));
      });
      location.hash == '';
    }
  }, [hash]);

  useEffect(() => {
    let metaRobots;
    if (existParams) {
      if (!location.search.replace('?redirect=1', '').length) {
        navigate(location.pathname);
        return;
      }
      metaRobots = document.createElement('meta');
      metaRobots.name = 'robots';
      metaRobots.content = 'NOINDEX,NOFOLLOW';
      document.head.insertBefore(metaRobots, document.head.firstElementChild);
    }

    return () => {
      metaRobots && metaRobots?.remove();
    };
  }, [existParams]);

  return (
    <QueryParamProvider location={location}>
      <div className={`wrapper ${className}`} ref={searchRef}>
        <HeaderAlertBar />
        <Header />
        <Cart />

        <main className="main" id="main">
          {searching && query ? (
            <div className="search-algolia">
              <SearchResult
                indexName={indexName}
                setIndexName={(indexName) => setState((s) => ({ ...s, indexName }))}
              />
            </div>
          ) : (
            children
          )}
          {searching && query && !hideChildren && children}
        </main>

        <Footer after_row={after_footer_after_row} before_row={after_footer_before_row} />
        <Customer />
      </div>
    </QueryParamProvider>
  );
}

export default memo(Layout);
